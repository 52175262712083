<template>
  <div ref="IntercityBusSettle" class="IntercityBusSettle">
    <div ref="functionButtons" class="function-buttons"></div>
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="85px"
      >
        <el-form-item label="发车时间：" class="code-time-range">
          <el-date-picker
            v-model="time"
            range-separator="至"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelect
            :clearable="true"
            :company-tree="companyList"
            :valuename="valuename"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>
        <el-form-item label="线路：">
          <el-select v-model="form.lineId" clearable filterable placeholder="请选择线路">
            <el-option
              v-for="role in lineList"
              :key="role.id"
              :label="role.name"
              :value="role.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="司机：">
          <el-select
            v-model="form.driverName"
            filterable
            remote
            reserve-keyword
            placeholder="请输入司机"
            clearable
            :remote-method="queryDriver"
            @clear="form.driverName = null"
          >
            <el-option
              v-for="item in driverList"
              :key="item.userName"
              :label="item.userName"
              :value="item.userName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车牌号：">
          <el-select
            v-model="form.licensePlateNumber"
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="请输入车牌号"
            :remote-method="queryCph"
            @clear="form.licensePlateNumber = null"
          >
            <el-option
              v-for="item in cphList"
              :key="item.licensePlateNumber"
              :label="item.licensePlateNumber"
              :value="item.licensePlateNumber"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="收款方：">
          <el-select v-model="form.ledgerReceiveId">
            <el-option
              v-for="role in ledgerReceiveList"
              :key="role.ledgerReceiveId"
              :label="role.ledgerReceiveName"
              :value="role.ledgerReceiveId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="box">
      <div class="item">
        <span>{{ totalData.totalPaymentCount || 0 }}</span>
        <p>收现订单数</p>
      </div>
      <div class="item">
        <span>{{ totalData.totalPrices || 0 }}</span>
        <p>收现金额</p>
      </div>
      <div class="item">
        <span>{{ totalData.ledgerPrices || 0 }}</span>
        <p>应分账金额</p>
      </div>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :col-class-type="true"
      :table-height="TableHeight"
    >
    </Table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { lastTime } from "@/tools/getTime.js";
import {
  getCompanyTree,
  ledgerExportDriverCashRecord,
  ledgerQueryDriverCashRecordPage,
  getVehicleList,
  getDriverList,
  getAllLine,
  listRedisCache,
} from "@/api/lib/api.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import moment from "moment";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        departureDateTimeBegin: null,
        departureDateTimeEnd: null,
        companyId: null,
        licensePlateNumber: null,
        driverName: null,
        lineId: null,
        ledgerReceiveId: null,
      },
      time: null,
      value: null,
      valuename: null,
      companyList: [],
      TableHeight: 0,
      tableData: [],
      titleName: [
        {
          title: " 发车时间",
          props: "departureDateTime",
        },
        {
          title: "公司",
          props: "companyName",
        },
        {
          title: "线路",
          props: "lineName",
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "收现订单数",
          props: "paymentCount",
        },
        {
          title: "收现金额",
          props: "totalPrices",
        },
        {
          title: "应分账金额",
          props: "ledgerIncome",
        },
        {
          title: "分账模式",
          SpecialJudgment: (res) => {
            return res.ledgerReceiveModel == 1
              ? `按比例（${res.ledgerReceiveFee}%）`
              : `按固定金额（${res.ledgerReceiveFee}元）`;
          },
        },
      ],
      total: 0,
      driverList: [],
      cphList: [],
      lineList: [],
      ledgerReceiveList: [],
      totalData: {},
    };
  },
  mounted() {
    const date = lastTime(new Date());
    const day = moment().format("YYYY-MM-DD");
    this.time = [date, day];
    this.getLedgerReceiveList();
    this.getCompanyList();
    this.getLineList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    queryFun() {
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.renderData();
    },
    exportFun() {
      let obj = this.deepClone(this.form);
      delete obj.currentPage;
      delete obj.pageSize;
      ledgerExportDriverCashRecord(obj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "电召收现分账 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击=
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    renderData() {
      if (this.time) {
        this.form.departureDateTimeBegin = this.time[0] + " 00:00:00";
        this.form.departureDateTimeEnd = this.time[1] + " 23:59:59";
      } else {
        this.form.departureDateTimeBegin = null;
        this.form.departureDateTimeEnd = null;
      }
      ledgerQueryDriverCashRecordPage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.totalData.totalPaymentCount = res.data.totalPaymentCount;
          this.totalData.totalPrices = res.data.totalPrices;
          this.totalData.ledgerPrices = res.data.ledgerPrices;
        }
      });
    },
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getGroupId(v) {
      if (v == null) {
        this.form.companyId = null;
      } else {
        this.form.companyId = v;
      }
    },
    getLineList() {
      getAllLine().then((res) => {
        if (res.code === "SUCCESS") {
          this.lineList = res.data;
        }
      });
    },
    getLedgerReceiveList() {
      listRedisCache().then((res) => {
        if (res.code === "SUCCESS") {
          this.ledgerReceiveList = res.data;
          this.form.ledgerReceiveId = res.data[0].ledgerReceiveId;
          this.queryFun();
        }
      });
    },
    queryCph(query) {
      this.cphList = [];
      if (query !== "") {
        setTimeout(() => {
          getVehicleList({
            licensePlateNumber: query,
            currentPage: 1,
            pageSize: 20,
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.cphList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.cphList = [];
      }
    },
    queryDriver(query) {
      this.driverList = [];
      if (query !== "") {
        setTimeout(() => {
          const data = {
            currentPage: 1,
            pageSize: 10,
            userName: query,
          };
          getDriverList(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.driverList = res.data.list;
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },
    computeHeight() {
      const wholeHeight = this.$refs.IntercityBusSettle.clientHeight;
      this.TableHeight = wholeHeight - 270 + "px";
    },
    // 分页页数发生变化
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
  },
};
</script>

<style scoped lang="scss">
.IntercityBusSettle {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 4px 20px;
  box-sizing: border-box;
  background: #ffffff;
  .code-time-range {
    .el-date-editor {
      width: 13vw !important;
      ::v-deep .el-range-separator {
        line-height: 24px;
      }
    }
  }
}
.box {
  margin-top: 16px;
  display: flex;
  .item {
    margin: 20px 40px;
    span {
      font-size: 16px;
      font-weight: bold;
    }
    p {
      margin-top: 10px;
    }
  }
}
.Table {
  margin-top: 16px;
}
.content {
  padding: 0 20px 20px 20px;
  .form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    font-size: 15px;
  }
}
</style>
